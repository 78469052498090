.card-tab {
  padding: 0 20px;
}

.selected-tab {
  background-color: #f6c23e;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button-handle {
  background-color: red;
  border-radius: 8px;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: red;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  font-weight: 600;
}

.form-control{
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}