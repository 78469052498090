/* ImageList.css */
.image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  
  .image-group {
    display: flex;
    width: 100%;
    gap: 4px;
  }
  /* .image-group.one {
    display: flex;
    width: 50%;
    gap: 4px;
    justify-content: end;
  } */
  
  .image-item {
    /* width: 33.33%;  */
    height: auto;
    max-width: 1000px;
  }
  .image-group .ant-image {
    flex: 1;
  }
  
  /* Điều chỉnh kích thước ảnh khi có 2 ảnh trên cùng một hàng */
  /* .image-group img{
    width: 50%;
  }

  .image-group.three img{
    width: 33%; 
  } */

  .image-group img{
    width: 100%;
  }
  
  
  