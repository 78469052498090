.item-overview {
  display: flex;
  flex-direction: column;
  width: 200px;
  margin-top: 8px;
}
.item-overview span {
  font-weight: 500px;
  text-align: center;
  margin-top: 8px;
}

.error-message {
  font-size: 12px;
  color: red;
  font-style: italic;
}



