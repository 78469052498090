.select-time {
  width: 100px;
}

.btn-next-time {
  cursor: pointer;
}

.column-staff-box {
  padding: 10px 0px;
  background: rgb(34, 197, 143);
  border-radius: 20px;
  color: white;
  width: 100%;
  text-align: center;
}

.column-in-out {
  padding: 10px 20px;
  background: rgb(196, 237, 196);
  border-radius: 10px;
}

.column-in-out-off-shift {
  background: rgb(244, 244, 186);
}

.circle-box {
  width: 10px;
  height: 10px;
  background: rgb(100, 188, 100);
  border-radius: 50%;
}

.circle-box-off-shift {
  width: 10px;
  height: 10px;
  background: yellow;
  border-radius: 50%;
}

.time-check-in-text {
  font-weight: bold;
  margin-left: 6px;
}

.check-in-hours-text {
  font-weight: 100;
  color: black;
  font-weight: bold;
}

.reason-text {
  color: black;
}

.history-time-keeping-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.modal-dialog {
  min-width: 50%;
}

.button-handle {
 
  background-color: #f6c23e;
  border-radius: 8px;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #f6c23e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  font-weight: 600;
}