.dates-group-icon {
    font-size: 25px;
    cursor: pointer;
}

.icon-group { 
    display: flex;
    align-items: center;
    width: 60px;
}

.select-date {
    width: 95%;
}