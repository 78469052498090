.link-text {
    color: blue;
    text-decoration: underline;
    font-style: oblique;
    font-size: 13px;
}

.rating {
    display: flex;
    justify-content: center;
}

.star-rating {
    font-size: 30px;
    color: #FFD600;
    font-weight: 300;
    opacity: 1
}

.things-to-improve {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.things-to-improve>label {
    font-weight: 750;
    font-size: 16px;
}

.option-improve {
    display: flex;
}

.improve-item {
    padding: 16px;
    background: #f2f1f8;
    border-radius: 10px;
    margin: 0px 10px 10px 0px;
}

.textarea {
    overflow: hidden;
}