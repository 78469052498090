.card-tab {
  padding: 0 20px;
}

.selected-tab {
  background-color: #f6c23e;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button-handle {
  background-color: #f6c23e;
  border-radius: 8px;
  color: white;
  border-width: 1px;
  border-style: solid;
  border-color: #f6c23e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 10px;
  font-weight: 600;
}