.btn-add {
  cursor: pointer;
}

.staff-modal {
  width: 50%;
  max-height: 95vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: none;
  border-radius: 4px;
  outline: none;
}

.label-title {
  display: flex;
  justify-content: space-between;
}

.salary-receives {
  position: fixed;
  display: flex;
  align-self: center;
  left: 0;
}

.salary-receives-text {
  font-weight: bold;
  font-size: 18px;
}

.body-model-cus {
  width: 80%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-flow: column;
}

