.header-page {
  display: flex;
  justify-content: space-between;
}

.table-shift {
  margin-top: 2rem;
}

.table-row {
  background: white;
  background-color: white !important;
}

.group-action-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: fit-content;
  justify-content: center;
  width: 100%;
}
.btn-edit {
  cursor: pointer;
  text-decoration: none;
  padding: 5px 20px;
  align-items: center;
  min-width: 100px;
  margin: 0;
}

.btn-edit:hover {
  text-decoration: none;
}

.btn-trash {
  cursor: pointer;
  margin-left: 5px;
  padding: 4px 20px;
  min-width: 100px;
  border: 0;
  background: var(--danger);
}

.btn-trash:hover {
  background-color: #e02d1b;
  border-color: #d52a1a;
}

.btn-trash-text {
  color: white;
}

.rs-picker-menu {
  z-index: 9999999;
}

.rs-picker-toggle-value {
  color: gray !important;
}

.rs-picker-toolbar-ranges {
  display: none;
}

.time-picker {
  width: 100%;
}

.date-of-week {
  border-radius: 9px;
  cursor: pointer;
}

.active {
  background-color: #f6c23e !important;
}

.scale-input {
  position: relative;
}

.scale-input-text {
  position: absolute;
  top: 0;
  right: 0;
}
