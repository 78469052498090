.view-salary {
  padding: 10px;
  border-radius: 5px;
  border: 1px dashed #000;
  cursor: pointer;
  margin-bottom: 15px;
}
.view-salary:hover {
  background-color: #f6c23e;
  color: white;
  border: 1px solid #f6c23e;
  font-weight: 600;
}
.salary-title {
  margin: 20px 0;
  padding: 10px 0px;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
  text-transform: capitalize;
}
.view-avatar {
  width: 100px;
  height: 100px;
  border: 5px solid #f6c23e;
  border-radius: 100rem;
  cursor: pointer;
}
.view-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100rem;
}
