.view-area {
  margin: 20px 0px;
}
.view-area div {
  background-color: #ccc;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  color: white;
}
.view-area .active {
  background-color: #f9c114;
  color: white;
}
