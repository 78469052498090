.donut {
    width: 2rem;
    height: 2rem;
    margin: 2rem;
    border-radius: 50%;
    border: 0.3rem solid rgba(255, 166, 0, 0);
    border-top-color: #979fd000;
    animation: 0s spin infinite linear;
    position: fixed;
    top: 40%;
    left: 48%;
    z-index: 9999;
}
.donut.multi {
    border-bottom-color: #979fd000;
}

.select-area {
    width: 240px;
}

.custom-input {
    border-radius: 15px;
    border: 1px #0c8af8 solid;
    padding: 4px 12px;
    background-color: white;
    height: 40px;
    box-shadow: 0 0 2px #0074d9;
  }

.custom-container {
    width: 100%;
}
  
.custom-container .rmdp-input {
    width: 100%;
    box-sizing: border-box;
    height: 26px;
}  

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
  
  .hoverable-image {
    width: 100px;
    height: 100px;
    display: flex;
    object-fit : cover;
    transition: opacity 0.3s ease-in-out; /* Tạo hiệu ứng transition */
  }
  
  .hoverable-image:hover .overlay-image {
    opacity: 0.7; /* Điều này giảm độ trong suốt của hình ảnh khi hover vào */
  }

  .image-container:hover .overlay-image {
    display: block; 
    opacity: 0.7;
  }
  
.overlay-image { 
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(209, 209, 209, 0.756);
    border-radius: 5px; 
    display: none; 
} 
  
.custom-dropdown .dropdown-content {
  display: none;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
}

.custom-dropdown .dropdown-button {
  background-color: #f1f1f1;
  color: black;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.custom-dropdown .dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.custom-dropdown .dropdown-content a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.custom-dropdown .dropdown-content a:hover {
  background-color: #ddd;
}

  
  