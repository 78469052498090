.table-work-schedule {
  /* display: inline-block; */
  vertical-align: top;
  max-width: 100%;
  /* overflow-x: auto; */
  white-space: nowrap;
  border-collapse: collapse;
  border-spacing: 0;
}
.table-work-schedule tbody {
  display: flex;
  width: 100%;
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  flex: 1 1;
  position: relative;
  flex-shrink: 0;
}
.table-work-schedule tr {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  flex: 1 1;
  border-left: 1px solid #d9d7ce;
}
.table-work-schedule td:not(:first-child) {
  min-width: 120px;
  min-height: 120px;
  cursor: pointer;
  border-bottom: 1px solid #d9d7ce;
}
.table-work-schedule tr:first-child {
  flex: 0 1;
  flex-basis: 250px;
  /* border-bottom: 1px solid #d9d7ce; */
}
.table-work-schedule tr:last-child {
  border-right: 1px solid #d9d7ce;
  /* border-bottom: 1px solid #d9d7ce; */
}
/* popup employee */
.employee-box {
  margin-bottom: 10px;
  cursor: pointer;
}
.employee-box div {
  padding: 5px 15px;
  background-color: #c9c8c8;
  color: #fff;
  border-radius: 7px;
  font-size: 15px;
  margin-right: 10px;
}
.employee-box .active {
  background-color: #f6c23e;
  align-items: center;
}
.extra-employee {
  font-size: 12px;
  color: rgb(143, 142, 142);
  margin-top: 10px;
  font-style: italic;
}
.stack-widget {
  position: relative;
  width: 100%;
  height: 100%;
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0px;
}

.overlay {
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(56 212 106 / 80%);
  border-radius: 5px;
  display: none; 
}

.container:hover .overlay {
  display: block; 
}

.infinite-scroll-component {
  overflow: unset !important;
}

table tbody th:first-child {
  position: sticky  !important;
  left: 0;
  z-index: 2 !important;
}

.my-modal-overlay{
  background-color: #00000053;
}

.item-data-container{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #d1e3ff;
  color: #4791ff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-placeholder {
  width: 60px;
  height: 60px;
  border-radius: 20%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6c23e;
}

.avatar-item {
  width: 25px;
  height: 25px;
  border-radius: 20%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6c23e;
}