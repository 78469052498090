.link-text {
    color: blue;
    text-decoration: underline;
    font-style: oblique;
    font-size: 13px;
}

.rating {
    display: flex;
    justify-content: center;
}

.star-rating {
    font-size: 30px;
    color: #FFD600;
    font-weight: 300;
    opacity: 1
}

.things-to-improve {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.things-to-improve>label {
    font-weight: 750;
    font-size: 16px;
}

.option-improve {
    display: flex;
}

.improve-item {
    padding: 16px;
    background: #f2f1f8;
    border-radius: 10px;
    margin: 0px 10px 10px 0px;
}

.textarea {
    overflow: hidden;
}

.text-area{
    font-size: 15px;
    margin-left: 5px;
}

/* .button-report {
    background: #ba0b0b;
    padding: 20xp;
    border-radius: 30px;
    box-shadow: 0 15px 30px 0 rgba(0,0,0,.25);
    color: #333;
    font-family: Readex Pro,sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    color: white;
    line-height: normal;
} */

.button-report {
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
    color: white;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    justify-content: center;
    line-height: normal;
    padding: 10px 20px;
}

.ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.ant-modal-body {
    /* flex: 1; */
    overflow: hidden;

}

.ant-modal-body > div{
    height: 100% !important;
}